
.link {
   
    position: relative;
    top: 7px;
    text-decoration:none;
    height:23px;
    vertical-align: middle !important;
    line-height: 14px;
   
}
@media (max-width: 1000px){
    .link {
        height: 25px;
        color:  black;
        line-height: 10px;
     
    }

 }
@media (max-width: 750px){
    .link {
        height: 25px;
        color:  black;
        line-height: 8px;
     
    }

 }
 @media (max-width: 555px){
    .link {

        line-height: 5px;
    }
    
 }
.navbar-toggler{
    margin-left:auto;
    margin-right:auto;
}
.link:hover {
    text-decoration:none;
   
}
.buttonCat{
    color:white;
    font-family: Pacifico !important;
}




.subNav{

 position: relative;
 top: -5px;


}
.navMain{
    text-align: left !important;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
   height: auto;
   justify-content: center;
   align-items: flex-start;
}
@media screen and (max-width: 1660px) {
    .navMain{
        height: 40px;
    }
}
@media screen and (max-width: 995px) {
    .navMain{
        display: flex;
        flex-wrap: wrap;
        height: 40px;
        justify-content: center;
        align-items: center;
        margin-top: 5px;
        
     
    }
}
@media screen and (max-width: 600px) {
    .navMain{
        position:relative;
        display: flex;
        flex-wrap: wrap;
        height: auto;
        justify-content: center;

        
    }
}
@media screen and (max-width: 345px) {
    .navMain{
        position:relative;
        display: flex;
        flex-wrap: wrap;
        height: auto;
        justify-content: center
        
    }
}
@media screen and (max-width: 700px) {
    .buttonCat{
        color:gray;
    }
}

.nav-item{
    padding-top:1px !important;
    padding-left: 2px !important;
    padding-right: 2px !important;
    padding-bottom:5px !important;
    margin:5px !important;
    height: 30px !important;
}
@media screen and (max-width: 1170px) {
    .nav-item{
        height: 30px !important;
        margin:3px !important;
    }
}
@media screen and (max-width: 785px) {
    .nav-item{
        height: 20px !important;
        margin:3px !important;
    }
}
@media screen and (max-width: 440px) {
    .nav-item{
        height: 20px !important;
        margin:7px 3px 2px 3px !important;
        
    }
}


