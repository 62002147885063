.spanx{
    margin-left:10px;
}
.button {
    color:white;
}
.link2 {
   
    position: relative;
    top: 7px;
    text-decoration:none;
    color:white;
    font-weight: bold;
    font-size: 20px;
    text-align: center;
    
    
   
}
@media (max-width: 700px){
    .link {
    
        color:  white;
    }
 }
 @media (max-width: 1103px){
    .link {
    
        font-size: 17px;
    }
 }
.navbar-toggler{
    margin-left:auto;
    margin-right:auto;
}
.link:hover {
    text-decoration:none;
   
}



.liNav {
    box-shadow : 1px 1px 10px rgba(255,255,255,0.3);
    height:40px;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
    margin-top:3px;
    margin-right: 20px;
    border-radius: 7px;
    color:white;
    
  
}
.liNav:hover{
    background-color: lightgray;
    opacity:0.5;
}
.mainNav{
   
    width: 100%;
    background-color:  #060b26;
 
  
}
@media (max-width: 700px){
    .mainNav {
        color:white;
        background-color:  #060b26;
    }
 }
.subNavBack{
 position: relative;
 top: -5px;



}
.navBack{
   height: 40px;
   justify-content: start;
   
}
@media screen and (max-width: 1660px) {
    .navBack{
        height: 40px;
    }
}
@media screen and (max-width: 1390px) {
    .navBack{
       width: 500px;
       display: grid;
       grid-template-columns:1fr 1fr 1fr 1fr 1fr;
       height: 80px;
      
        
    }
}
@media screen and (max-width: 995px) {
    .navBack{
        display: flex;
        color:white;
        height: 360px;
    }
}
@media screen and (max-width: 1660px) {
    .subNavBack{
        margin-left:0%;
    }
}
@media screen and (max-width: 1360px) {
    .subNavBack{
        margin-left:-1%;
    }
}
@media screen and (max-width: 1290px) {
    .subNavBack{
        margin-left:-1%;
    }
}
@media screen and (max-width: 1110px) {
    .subNavBack{
        margin-left:-2%;
    }
}
@media screen and (max-width: 995px) {
    .subNavBack{
        margin-left:1%;
    }
}

@media screen and (max-width: 1500px) {
    .liNav {
        width: auto;
        margin-left: 5px;
        margin-right: 5px;
    }
}
@media screen and (max-width: 1280px) {
    .liNav {
        width: auto;
        margin-left: 5px;
        margin-right: 5px;
      
    }
}
@media screen and (max-width: 1070px) {
    .liNav {
        width: auto;
    }
}
.highlight{
    border-bottom: solid white;
    border-radius: 0px;
}
@media (max-width: 700px){
    .highlight {
    
        border-bottom: solid red;
    }
 }
