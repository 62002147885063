.container {
    height: 100%;
    width: 100%;
    display: table;
    overflow: hidden;
    
  }
  .container .car-wrapper {
    display: table-cell;
    vertical-align: middle;
    width: 100%;
  }
  .car {
    width: 20em;
    height: 12.5em;
    position: relative;
    opacity: 1;
    margin-left: auto;
    margin-right: auto;
    z-index: 4;
   
    -webkit-animation: carMove 0.5s infinite;
    -moz-animation: carMove 0.5s infinite;
    -ms-animation: carMove 0.5s infinite;
    -o-animation: carMove 0.5s infinite;
    animation: carMove 0.5s infinite;
  }
  .car .body {
    position: absolute;
    top: 0;
    left: 0.875em;
    width: 14.375em;
    height: 10em;
    background: #fff;
  }
  .car .body:before {
    content: "";
    position: absolute;
    left: -0.875em;
    border-radius: 10px 10px 0 0;
    width: 15.875em;
    z-index: 2;
    height: 0.75em;
    background: #9D8877;
  }
  .car .body div {
    position: absolute;
    background: #fff;
    width: 18.125em;
    height: 3.75em;
    bottom: -0.625em;
    border-radius: 0 0 10px 10px;
  }
  .car .body div:before {
    content: "";
    background: #fff;
    position: absolute;
    top: -1.8125em;
    right: 0px;
    width: 5em;
    height: 2.5em;
    z-index: 1;
    border-radius: 0 14px 0 0;
    -webkit-transform: rotate(17deg);
    -moz-transform: rotate(17deg);
    -ms-transform: rotate(17deg);
    -o-transform: rotate(17deg);
    transform: rotate(17deg);
  }
  .car .body div:after {
    content: "";
    background: #fff;
    position: absolute;
    top: -5.125em;
    right: 1.0625em;
    width: 5.625em;
    height: 2.5em;
    z-index: 1;
    border-radius: 10px 0 0 0;
    -webkit-transform: rotate(75deg);
    -moz-transform: rotate(75deg);
    -ms-transform: rotate(75deg);
    -o-transform: rotate(75deg);
    transform: rotate(75deg);
  }
  .car .wheel {
    position: absolute;
    width: 3.75em;
    height: 3.75em;
    background: #3c464c;
    border-radius: 50%;
    bottom: 0;
    left: 3em;
    z-index: 14;
    -webkit-animation: carMove 1s 1s;
    -moz-animation: carMove 1s 1s;
    -ms-animation: carMove 1s 1s;
    -o-animation: carMove 1s 1s;
    animation: carMove 1s 1s;
  }
  .car .wheel:before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    background: #9D8877;
    width: 1.5em;
    height: 1.5em;
    border-radius: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .car .wheel:nth-of-type(2) {
    left: 13.125em;
  }
  .car .decos {
    width: 18.125em;
    height: 10.5em;
    position: absolute;
    z-index: 12;
    top: 0;
    left: 0.875em;
  }
  .car .line-bot {
    position: absolute;
    bottom: 0.5625em;
    height: 3px;
    width: 100%;
    background: #e1e8ef;
  }
  .car .door {
    position: absolute;
    left: 7.5em;
    bottom: 0;
    width: 3.75em;
    height: 8.8125em;
    border-radius: 10px 10px 0 0;
    border: 3px solid #e1e8ef;
    border-bottom: 0;
    background: #fff;
  }
  .car .door:after {
    content: "";
    position: absolute;
    top: 0.375em;
    left: 0.4375em;
    width: 2.875em;
    height: 2.5em;
    border-radius: 4px;
    background: #9D8877;
  }
  .car .door .handle {
    position: absolute;
    right: 0.625em;
    top: 4.375em;
    width: 0.625em;
    height: 0.625em;
    background: #e1e8ef;
    border-radius: 50%;
  }
  .car .door .handle:after {
    content: "";
    width: 1.25em;
    height: 0.25em;
    border-radius: 10px;
    background: #e1e8ef;
    position: absolute;
    top: 50%;
    left: -0.3125em;
    margin-top: -0.125em;
  }
  .car .door .bottom {
    position: absolute;
    bottom: 0.375em;
    left: 0.6875em;
    width: 2.375em;
    height: 0.75em;
  }
  .car .door .bottom:before, .car .door .bottom:after {
    content: "";
    display: block;
    width: 100%;
    height: 0.1875em;
    background: #e1e8ef;
    height: 0.25em;
    border-radius: 10px;
    margin-bottom: 0.25em;
  }
  .car .window {
    position: absolute;
    top: 1.125em;
    left: 12.5em;
    width: 2em;
    background: #9D8877;
    height: 4.5em;
    border-radius: 10px 10px 0 10px;
  }
  .car .window:before {
    content: "";
    width: 100%;
    height: 1.25em;
    background: #9D8877;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 2.9375em;
    border-radius: 0 10px 10px 10px;
  }
  .car .window:after {
    content: "";
    height: 1.25em;
    background:#9D8877;
    position: absolute;
    top: 1.0625em;
    left: -0.8125em;
    width: 4.1875em;
    transform-origin: bottom;
    -webkit-transform: rotate(74deg);
    -moz-transform: rotate(74deg);
    -ms-transform: rotate(74deg);
    -o-transform: rotate(74deg);
    transform: rotate(74deg);
    border-radius: 10px 10px 10px 10px;
  }
  .car .light {
    position: absolute;
    width: 0.625em;
    height: 0.625em;
    background: #ff8c40;
    right: 3.75em;
    bottom: 3.125em;
    border-radius: 3px;
  }
  .car .light-front {
    position: absolute;
    width: 0.5em;
    height: 0.5em;
    background: #ff8c40;
    right: -0.1875em;
    bottom: 3.5625em;
    border-radius: 3px;
  }
  .car .light-front:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: #ff8c40;
    top: 100%;
    height: 0.9375em;
    border-radius: 3px;
    margin-top: 2px;
  }
 
  .wind {
    position: absolute;
    z-index: -1;
    right: 100%;
    height: 100%;
    margin-right: -1.25em;
  }
  .wind .p {
    position: absolute;
    background: #000;
    border-radius: 0.625em;
    height: 0.625em;
  }
  .wind .p1 {
    width: 4.375em;
    position: absolute;
    right: 0;
    top: 1.5625em;
    animation: wind 1s -1s linear infinite;
  }
  .wind .p2 {
    width: 6.25em;
    right: 2.5em;
    top: 3.75em;
    animation: wind 1s -2s linear infinite;
  }
  .wind .p3 {
    width: 3.125em;
    right: 0em;
    top: 6.5625em;
    animation: wind 1s -1s linear infinite;
  }
  .wind .p4 {
    width: 3.75em;
    right: 6.25em;
    top: 8.4375em;
    animation: wind 1s 1s linear infinite;
  }
  .wind .p5 {
    width: 4.375em;
    right: 1.875em;
    top: 9.0625em;
    animation: wind 1s -1.5s linear infinite;
  }
  .car-wrapper_inner {
    position: relative;
    z-index: 4;
    margin-bottom:-80px;
    transform: scale(0.8);
  }
  @media (max-width: 700px){
    .car-wrapper_inner {
      z-index: 25;
      transform: scale(0.5);
      margin-bottom:-140px;
    }
 }
  @keyframes carMove {
    0% {
      transform: translateY(0px);
    }
    50% {
      transform: translateY(-1px);
    }
    100% {
      transform: translateY(0px);
    }
  }
  @keyframes wind {
    0% {
      transform: translateX(100%);
      opacity: 1;
    }
    100% {
      transform: translateX(-400%);
      opacity: 0;
    }
  }
  .car_outter {
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    transform: translateX(-100%);
   
    position: relative;
    animation: carDrive 10s 5s cubic-bezier(0.88, 0.08, 0.16, 1.1) infinite;
  }
  @keyframes carDrive {
    0% {
      transform: translateX(-100%);
    }
    25% {
      transform: translateX(0%);
    }
    70% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(100%);
    }
  }
  @keyframes bgIn {
    0% {
      left: 100%;
    }
    100% {
      left: -100%;
    }
  }
  @keyframes wrapperIn {
    0% {
      transform: scale(0);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }