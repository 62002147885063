

@import url('https://fonts.googleapis.com/css2?family=Maven+Pro&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@600;700&display=swap");


*{
box-sizing:border-box;
margin:0;
padding:0;
font-family: "Montserrat", sans-serif;

}
::-webkit-scrollbar {
  width: 8px;
  height: 7px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(154, 155, 160, 0.3);
}

::-webkit-scrollbar-thumb {
background-color: rgb(173, 153, 153);
outline: 1px solid rgb(58, 61, 65);
}
html, body{
  height: 100%;
}
label{
  margin-bottom: 0.1rem !important;
}
.link-mod{
  color: white;
  text-decoration: none;
}
.TimePicker{
  font-size: 14px;

}
.panelBtn {
  text-decoration: none;
  color: black;
  padding: 3px;
}
.text {
  font-weight: 400;
  font-size: 14px;
  text-decoration: none;
  color: white;
  margin-left: 5px;
  margin-right:5px;
  white-space: nowrap;
}

.text2 {
  margin-left: 50px;
  white-space: nowrap;
}

@media (max-width: 1110px) {
  .text {
    font-size: 14px;
  }
}
@media (max-width: 900px) {
  .text {
    font-size: 12px;
  }
}
.insta {
  height: 40px;
  width: 40px;
  margin-right: 10px;
  margin-top: auto;
  margin-bottom: auto;
  background-image: url("/public/img/insta.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border: none;
  cursor: pointer;
  margin-left:5px;
}
@media (max-width: 1110px) {
  .insta {
    margin-left:5px;
    margin-right: 5px;
    height: 30px;
    width: 30px;
  }
}


@media (max-width: 800px) {
  .insta {
    
    height: 30px;
    width: 30px;
  }
}

.face {
  height: 40px;
  width: 40px;
  margin-left: 10px;
  margin-top: auto;
  margin-bottom: auto;
  background-image: url("/public/img/face.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border: none;
  cursor: pointer;
}
@media (max-width: 1110px) {
  .face {
    margin-left: 5px;
    height: 30px;
    width: 30px;
  }
}
@media (max-width: 800px) {
  .face {
    height: 30px;
    width: 30px;
  }
}


  .hmove { display: flex; }
  
  /* (C) ITEMS - INTO A LONG HORIZONTAL ROW */
  .hitem {
    font-weight: 800;
    color:inherit;
    flex-shrink: 0;
    width: auto;
    margin-right: 150px;
    margin-left:10px;
    box-sizing: border-box;
    padding: 10px;
    text-align: center;
  }
   
  /* (D) ANIMATION - MOVE ITEMS FROM RIGHT TO LEFT */
  /* 4 ITEMS -400%, CHANGE THIS IF YOU ADD/REMOVE ITEMS */
  @keyframes tickerh500 {
    0% { transform: translate3d(-300% ,0, 0); }
    100% { transform: translate3d(100%, 0, 0); }
  }
  @keyframes tickerh {
    0% { transform: translate3d(-75% ,0, 0); }
    100% { transform: translate3d(100%, 0, 0); }
  }
  .hmove { animation: tickerh 25s linear infinite reverse ; }
  .hmove:hover { animation-play-state: paused; }

  @media only screen and (max-width: 600px) {
    .hmove { animation: tickerh500 20s linear infinite reverse; }
  }